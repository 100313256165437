(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/race-picker-item/assets/javascripts/race-picker-item.js') >= 0) return;  svs.modules.push('/components/racing/race-picker-item/assets/javascripts/race-picker-item.js');

'use strict';

function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
const useMemo = React.useMemo;
const {
  gameTypes,
  gameTypeToSubProductId
} = svs.racing.common.constants;
const {
  raceHasGameType
} = svs.racing.common.util;
const {
  getRaceDescription
} = svs.racing.common.strings;
const {
  getRaceStarted,
  getRaceInit
} = svs.racing;
const GameTypeTag = svs.racing.gameTypeTag.GameTypeTag;
const Flag = svs.lb_ui.ReactFlag;
const Jackpot = _ref => {
  var _jackpot$jackpots;
  let {
    hasTop5,
    jackpot,
    presentExtraMoney,
    raceId
  } = _ref;
  const jackpotAmount = jackpot && jackpot.jackpots && jackpot.jackpots[0].jackpot;
  const isJackpot = hasTop5 && (jackpot === null || jackpot === void 0 || (_jackpot$jackpots = jackpot.jackpots) === null || _jackpot$jackpots === void 0 ? void 0 : _jackpot$jackpots.filter(jackpotsUserProduct => jackpotsUserProduct.userProduct === gameTypeToSubProductId[gameTypes.TOPP5]).length) > 0;
  const isExtraMoney = !hasTop5 && presentExtraMoney;
  if (jackpotAmount && raceId === jackpot.raceId) {
    return React.createElement(React.Fragment, null, isJackpot && React.createElement("div", {
      className: "race-picker-item-jackpot"
    }, React.createElement("i", {
      className: "icon-default icon-jackpot"
    }), React.createElement("div", {
      className: "jackpot-text"
    }, "Jackpot ".concat(jackpotAmount, " kr"))), isExtraMoney && React.createElement("div", {
      className: "race-picker-item-jackpot"
    }, React.createElement("i", {
      className: "icon-default icon-money"
    }), React.createElement("div", {
      className: "jackpot-text"
    }, "Extrapengar ".concat(jackpotAmount, " kr"))));
  }
  return null;
};
const RacePickerItem = _ref2 => {
  var _jackpot$jackpots2;
  let {
    raceSummary,
    meeting,
    cb,
    isSelected,
    isArena,
    isLast,
    lastItemRef,
    isStreamplayerHeader,
    jackpot
  } = _ref2;
  const hasTop5 = raceHasGameType(raceSummary, gameTypes.TOPP5);
  const arenaTop5 = hasTop5 && isArena;
  const couponTop5 = hasTop5 && !isArena;
  const isAllDrawsDisabled = raceSummary.draws.filter(draw => draw.disabled).length === raceSummary.draws.length;
  const presentExtraMoney = Boolean(jackpot !== undefined && (jackpot === null || jackpot === void 0 || (_jackpot$jackpots2 = jackpot.jackpots) === null || _jackpot$jackpots2 === void 0 ? void 0 : _jackpot$jackpots2.filter(jackpotsUserProduct => jackpotsUserProduct.userProduct !== gameTypeToSubProductId[gameTypes.TOPP5]).length) > 0 && raceSummary.raceId === (jackpot === null || jackpot === void 0 ? void 0 : jackpot.raceId));
  const renderLabels = () => {
    if (jackpot.raceId === raceSummary.raceId) {
      const extraMoneyGameMode = jackpot.jackpots.filter(jackpotsUserProduct => jackpotsUserProduct.userProduct !== gameTypeToSubProductId[gameTypes.TOPP5]);
      if (extraMoneyGameMode.length > 0) {
        const label = extraMoneyGameMode[0].userProduct;
        const gameTypeProp = Object.keys(gameTypeToSubProductId).find(key => gameTypeToSubProductId[key] === label);
        return React.createElement(GameTypeTag, {
          gameType: gameTypeProp,
          isInverted: isSelected
        });
      }
      return null;
    }
  };
  const descriptionString = useMemo(() => getRaceDescription(raceSummary), [raceSummary]);
  const pickerClasses = [getRaceStarted(raceSummary.raceStatus) ? 'race-started' : 'race-not-started'];
  if (arenaTop5 && !isStreamplayerHeader) {
    pickerClasses.push('race-picker-item-extended');
  }
  if (isArena && presentExtraMoney && !isStreamplayerHeader) {
    pickerClasses.push('race-picker-item-extended');
  }
  if (isSelected) {
    pickerClasses.push('race-picker-item-selected');
  }
  if (isLast) {
    pickerClasses.push('race-picker-item-last');
  }
  return React.createElement("div", _extends({
    className: "race-picker-item ".concat(pickerClasses.join(' ')),
    ref: isLast ? lastItemRef : null
  }, !isSelected && cb && {
    onClick: () => cb(raceSummary.raceId)
  }), React.createElement("div", {
    className: "item-track-name"
  }, React.createElement("span", {
    className: "item-flag-and-track"
  }, raceSummary.countryCode && React.createElement(Flag, {
    iso: raceSummary.countryCode,
    size: "100"
  }), "\xA0", " ".concat(meeting.track.name)), React.createElement("span", {
    className: "right align-right".concat(isAllDrawsDisabled && !isSelected ? ' fc-red' : '')
  }, isAllDrawsDisabled && 'Tillfälligt stängt', getRaceInit(raceSummary.startTime, raceSummary.raceStatus))), React.createElement("div", {
    className: "item-race-container"
  }, React.createElement("div", {
    className: "item-race-number"
  }, "Lopp ".concat(raceSummary.raceNumber)), React.createElement("div", {
    className: "item-race-code"
  }, "[M".concat(raceSummary.meeting.meetingNumber, "R").concat(raceSummary.raceNumber, "]"))), React.createElement("div", {
    className: "item-start-info"
  }, React.createElement("span", null, descriptionString), !isStreamplayerHeader && React.createElement("span", {
    className: "right item-game-type"
  }, couponTop5 && hasTop5 && React.createElement(GameTypeTag, {
    gameType: gameTypes.TOPP5,
    isInverted: isSelected
  }), !isArena && presentExtraMoney && !hasTop5 && !isStreamplayerHeader && renderLabels()), isStreamplayerHeader && !hasTop5 && React.createElement("span", {
    className: "right item-game-type"
  }, presentExtraMoney && renderLabels()), arenaTop5 && isStreamplayerHeader && !svs.core.detect.formfactor.mobile() && React.createElement("span", {
    className: "right item-game-type"
  }, React.createElement(GameTypeTag, {
    gameType: gameTypes.TOPP5,
    isInverted: isSelected
  }))), isArena && !isStreamplayerHeader && React.createElement("div", {
    className: "item-jackpot"
  }, React.createElement("span", null, arenaTop5 && React.createElement(GameTypeTag, {
    gameType: gameTypes.TOPP5
  }), presentExtraMoney && !hasTop5 && renderLabels()), React.createElement(Jackpot, {
    hasTop5: hasTop5,
    jackpot: jackpot,
    presentExtraMoney: presentExtraMoney,
    raceId: raceSummary.raceId
  })));
};
svs.racing.RacePickerItem = RacePickerItem;

 })(window);