(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/race-picker-item/assets/javascripts/race-picker-item-status.js') >= 0) return;  svs.modules.push('/components/racing/race-picker-item/assets/javascripts/race-picker-item-status.js');

'use strict';

const {
  formatDate
} = svs.components.lbUtils.helpers.formatDate;
const raceStatuses = svs.racing.common.constants.raceStatuses;
const getStartDay = text => React.createElement("div", {
  className: "race-picker-status-holder"
}, React.createElement("i", {
  className: "icon-default icon-clock-simple"
}), React.createElement("span", {
  className: "race-picker-status-text"
}, text));
const getTimeToStart = time => getStartDay(formatDate(time, true, true, true));
const getStatus = (status, text, icon, time) => React.createElement("div", {
  className: "race-picker-status-holder race-status-".concat(status)
}, icon && React.createElement("i", {
  className: "icon-default ".concat(icon)
}), React.createElement("span", {
  className: "race-picker-status-text"
}, text), time && React.createElement("div", {
  className: "race-picker-status-time"
}, React.createElement("i", {
  className: "icon-default icon-clock-simple"
}), React.createElement("span", {
  className: "race-picker-status-text"
}, formatDate(time, true, true, true))));
const getRaceStarted = status => {
  if (!status) {
    return false;
  }
  switch (status) {
    case raceStatuses.FALSE_START:
    case raceStatuses.START:
    case raceStatuses.STARTED:
    case raceStatuses.CANCELLED:
    case raceStatuses.RESULT_ENTERED:
    case raceStatuses.RESULT_ENTERED_PHOTO:
    case raceStatuses.RESULT_ENTERED_VALIDATED:
    case raceStatuses.RESULT_OFFICIAL:
    case raceStatuses.RESULT_OFFICIAL_PHOTO:
    case raceStatuses.RACE_FINALIZED:
      return true;
    default:
      return false;
  }
};
const getRaceInit = (time, status) => {
  if (!time) {
    return false;
  }
  switch (status) {
    case raceStatuses.START:
    case raceStatuses.STARTED:
      return getStatus(status.toLowerCase(), 'Pågår', 'icon-play-circle', time);
    case raceStatuses.STOPPED:
      return getStatus(status.toLowerCase(), 'Avbrutet', 'icon-remove-sign', time);
    case raceStatuses.RUNNERS_ON_TRACK:
      return getStatus(status.toLowerCase(), '< 5 min', 'icon-clock-simple');
    case raceStatuses.START_IN_3_MINUTES:
      return getStatus(status.toLowerCase(), '< 3 min', 'icon-clock-simple');
    case raceStatuses.RUNNERS_WAITING_FOR_START:
      return getStatus(status.toLowerCase(), '< 1 min', 'icon-clock-simple');
    case raceStatuses.FALSE_START:
      return getStatus(status.toLowerCase(), 'Omstart', 'icon-play-circle', time);
    case raceStatuses.RESULT_ENTERED:
    case raceStatuses.RESULT_ENTERED_PHOTO:
    case raceStatuses.RESULT_ENTERED_VALIDATED:
      return getStatus(status.toLowerCase(), 'Prel. resultat', 'icon-finish-flag', time);
    case raceStatuses.RESULT_OFFICIAL:
    case raceStatuses.RESULT_OFFICIAL_PHOTO:
    case raceStatuses.RACE_FINALIZED:
      return getStatus(status.toLowerCase(), 'Avgjort', 'icon-finish-flag', time);
    case raceStatuses.CANCELLED:
      return getStatus(status.toLowerCase(), 'Inställt', 'icon-remove-sign', time);
    case raceStatuses.RUNNERS_READY:
    case raceStatuses.JOCKEY_CHANGE:
    default:
      return getTimeToStart(time);
  }
};
svs.racing.getRaceInit = getRaceInit;
svs.racing.getRaceStarted = getRaceStarted;

 })(window);