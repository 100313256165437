(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/race-picker-item/assets/javascripts/race-picker-light.js') >= 0) return;  svs.modules.push('/components/racing/race-picker-item/assets/javascripts/race-picker-light.js');
'use strict';

const useMemo = React.useMemo;
const {
  getRaceDescription
} = svs.racing.common.strings;
const Flag = svs.lb_ui.ReactFlag;
const RacePickerItemLight = _ref => {
  let {
    raceSummary,
    meeting,
    className = ''
  } = _ref;
  const descriptionString = useMemo(() => getRaceDescription(raceSummary, false, true), [raceSummary]);
  return React.createElement("div", {
    className: className
  }, React.createElement("div", {
    className: "margin-bottom"
  }, React.createElement("span", {
    className: "item-flag-and-track"
  }, raceSummary.countryCode && React.createElement(Flag, {
    iso: raceSummary.countryCode,
    size: "100"
  }), "\xA0", " ".concat(meeting.track.name), ' ', "|", ' ', "Lopp ".concat(raceSummary.raceNumber))), React.createElement("div", {
    className: "fc-grey-500"
  }, React.createElement("span", null, descriptionString)));
};
svs.racing.RacePickerItemLight = RacePickerItemLight;

 })(window);